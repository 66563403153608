<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Netz {{ network.title }}</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <form @submit="update_network" class="form" method="post">
            <div class="form-wrap">
              <label for="name">{{ $t("global.name") }}</label>
              <input v-model="network.title" class="form-input" type="text" name="name" id="name" />
            </div>

            <div class="form-wrap">
              <label for="description">Inhalt</label>
              <textarea v-model="network.description" rows="6" class="form-input" name="description" id="description"></textarea>
            </div>

            <div class="content-wrap">
              <header class="content-header cf"><h3>Geografische Eingrenzung</h3></header>
              <div class="content-body">
                <MapView
                  :initialZoom="9"
                  :getPosition="true"
                  :adresssearch="true"
                  :showCircle="true"
                  :myMarker="true"
                  :clickRadius="true"
                  :editRadius="true"
                  :listView="true"
                  :selectedDevices.sync="network.devices"
                ></MapView>
              </div>
            </div>

            <p style="margin-bottom: 0; text-align: right">
              <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round" />
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";
import MapView from "@/components/MapView";

export default {
  name: "dashboard",
  components: {
    AdminNav,
    MapView,
  },
  data() {
    return {
      errors: [],
      network: {
        title: "",
        description: "",
        devices: [],
      },
      selectedDevices: [],
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/networks/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.network = response.data.network;
          console.log(this.network);
        });

      this.get_industries(1);
    },
    update_network: function (e) {
      e.preventDefault();

      axios
        .patch(process.env.VUE_APP_BASE_API + "/admin/networks/" + this.$route.params.uuid, this.network, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich gespeichert.",
          });
          this.get_data();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
